<template>
    <div class="home-membership-wrap">
        <div class="membership-container">
            <div class="membership-text-info">
                <p class="membership-title">Winex Membership</p>
                <h4 class="membership-content-title">
                    와넥스의 파인 와인 NFT<br/>
                    <span class="bold">멤버쉽으로 200% 즐겨보세요!</span>
                </h4>
                <div class="membership-mobile-image"></div>
                <h5 class="membership-benefit-title">Lazy Cat Wine Club</h5>
                <ul class="membership-benefit-list">
                    <li class="membership-benefit-item">파인 와인 NFT 민팅 참여 가능</li>
                    <li class="membership-benefit-item">와넥스 NFT 거래 수수료 4% 에서 2%로 할인</li>
                    <li class="membership-benefit-item">한국으로 와인 냉장 컨테이너 운송비 3년간 무료</li>
                    <li class="membership-benefit-item">프랑스 현지 와인 창고 보관료 3년간 무료</li>
                    <li class="membership-benefit-item">1만개의 LCWC 캐릭터들은 모두 다르며, 소유자에게 소유권의 독점, 거래도 가능</li>
                    <li class="membership-benefit-item">PFP(Profile Picture)로 나만의 아이덴티티로 사용가능</li>
                    <li class="membership-benefit-item">현지 와이너리 투어 by Winex Council</li>
                    <li class="membership-benefit-item">스페셜 파인 와인 테이스팅 & 세미나 by WC</li>
                    <li class="membership-benefit-item">럭셔리 프라이빗 파티 by WC</li>
                    <li class="membership-benefit-item">와인 구매시 할인, 콜키지 프리 등 다양한 제휴 업체 혜택</li>
                    <!--<li class="membership-benefit-item">그 자체로 리미티드 에디션이며 자유롭게 거래 가능</li>-->
                </ul>
                <router-link :to="{path: `/membership`}" class="move-btn-wrap">
                <DefaultButton
                :class-list="'primary-btn w120 h40'"
                :content="'더보기'"></DefaultButton>
                </router-link>
            </div>
            <div class="membership-pc-image-info">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
    import DefaultButton from "../buttons/DefaultButton";
    export default {
        name: "MembershipInfo",
        components: {DefaultButton}
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/home-membership.scss";
</style>
